<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <b-card>
          <b-row align-h="center" v-if="dataLoading">
            <b-spinner
              class="m-5"
              style="width: 4rem; height: 4rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </b-row>

          <b-row class="mt-1" v-if="!dataLoading">
            <b-col xl="2" lg="2" md="3" sm="12" cols="12">
              <b-form-group
                label="Report type"
                invalid-feedback="Type is required."
                ref="type"
              >
                <v-select
                  v-model="myObj.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :clearable="false"
                  ref="type"
                  label="text"
                  placeholder="Select type"
                  @input="setFormat()"
                  @search:blur="CheckType()"
                />
              </b-form-group>
            </b-col>

            <b-col xl="2" lg="2" md="3" sm="12" cols="12">
              <b-form-group
                label="Report format"
                invalid-feedback="format is required."
                ref="format"
              >
                <v-select
                  v-model="myObj.format"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="formatOptions"
                  :clearable="false"
                  ref="format"
                  placeholder="Select format"
                  @input="setDateOption()"
                  @search:blur="CheckFormat()"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="myObj.format == 'Discount Percentage'"
            >
              <b-form-group
                label="Percentage"
                invalid-feedback="Percentage is required."
                ref="perc"
              >
                <b-form-input
                  placeholder="Enter percentage"
                  type="number"
                  v-model="disPerc"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col
              v-if="myObj.format == 'Monthly summary'"
              xl="3"
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Select Department"
                invalid-feedback="Department is required."
                ref="departs"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="myObj.depID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="departments"
                  :clearable="false"
                  ref="departs"
                  label="name"
                  :reduce="(val) => val.id"
                  placeholder="Select department"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="singleVisible.includes(myObj.format)"
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Select Month"
                invalid-feedback="Month is required."
                ref="sMonth"
              >
                <v-select
                  v-model="singleMonth"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="monthsSOptions"
                  :clearable="false"
                  ref="sMonth"
                  label="name"
                  :reduce="(val) => val.id"
                  placeholder="Select month"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="singleVisible.includes(myObj.format)"
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Select year"
                invalid-feedback="year is required."
                ref="year"
              >
                <v-select
                  v-model="singleYear"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="yearSOptions"
                  :clearable="false"
                  ref="year"
                  label="title"
                  :reduce="(val) => val.id"
                  placeholder="Select year"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="
                this.myObj.type.value == 'dcr' &&
                !['Exemption', 'Summary Details'].includes(myObj.format)
              "
            >
              <b-form-group
                label="Collection Account"
                invalid-feedback="collection account is required."
                ref="acc"
              >
                <v-select
                  v-model="myObj.account"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accTypes"
                  :reduce="(opt) => opt.id"
                  label="account"
                  :clearable="false"
                  ref="acc"
                  placeholder="Select account"
                  @input="CheckAccount()"
                />
              </b-form-group>
            </b-col>

            <template v-if="this.myObj.format == 'Slip range'">
              <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                <b-form-group
                  label="Slip From"
                  invalid-feedback="Slip From is required."
                  ref="spFrom"
                >
                  <b-form-input
                    placeholder="Enter slip no."
                    type="number"
                    v-model="slipFrom"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                <b-form-group
                  label="Slip To"
                  invalid-feedback="Slip To is required."
                  ref="spTo"
                >
                  <b-form-input
                    placeholder="Enter slip no."
                    type="number"
                    v-model="slipTo"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </template>

            <b-col xl="2" lg="2" md="3" sm="12" cols="12" v-if="false">
              <b-form-group
                label="Accounts"
                invalid-feedback="accounts is required."
                ref="accounts"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="accList"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accTypes"
                  :reduce="(opt) => opt.id"
                  label="account"
                  :clearable="false"
                  ref="accounts"
                  placeholder="Select account"
                  @input="setAccounts()"
                  @search:blur="checkMultiAccounts()"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="
                (this.myObj.type.value == 'Accounts' &&
                  this.myObj.format !== 'Closing report' &&
                  this.myObj.format !== 'Cashbook' &&
                  myObj.format !== 'Monthly Fee' &&
                  myObj.format !== 'Estimation') ||
                myObj.format == 'Ledger'
              "
            >
              <b-form-group
                label="Chart of account"
                invalid-feedback="Chart of account is required."
                ref="acc"
              >
                <v-select
                  multiple
                  v-model="coa"
                  :closeOnSelect="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="coaTypes"
                  :reduce="(opt) => opt.id"
                  label="title"
                  :clearable="false"
                  @input="setAccountsCOA()"
                  ref="acc"
                  placeholder="Select chart of account"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="
                statusVisible.includes(myObj.format) ||
                (myObj.type.value == 'defaulters' &&
                  !singleVisible.includes(myObj.format) &&
                  myObj.format != 'Summary' &&
                  myObj.format != 'Challan Statement')
              "
            >
              <!-- v-if="this.myObj.format == 'Class list'" -->
              <b-form-group
                label="Status"
                invalid-feedback="Status is required."
                ref="status"
              >
                <v-select
                  v-model="myObj.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="(opt) => opt.value"
                  label="text"
                  :clearable="false"
                  ref="status"
                  placeholder="Select status"
                  @input="CheckStatus()"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              v-if="
                ['Staff List', 'Staff List Date wise'].includes(myObj.format)
              "
            >
              <b-form-group
                label="Status"
                invalid-feedback="Status is required."
                ref="stf_status"
              >
                <v-select
                  v-model="myObj.staffStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="staffStOptions"
                  :reduce="(opt) => opt.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select status"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="myObj.format == 'Consents'"
            >
              <b-form-group
                label="Consent"
                invalid-feedback="Consent is required."
                ref="cons"
              >
                <v-select
                  v-model="myObj.consent"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="consentList"
                  :clearable="false"
                  placeholder="Select"
                  @input="CheckCons()"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="
                myObj.type.value == 'defaulters' &&
                (myObj.format == 'Class wise' || myObj.format == 'Family wise')
              "
            >
              <b-form-group
                label="Balance over"
                invalid-feedback="Balance is required."
                ref="balance"
              >
                <b-form-input
                  placeholder="Enter balance"
                  type="number"
                  v-model="myObj.balance"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="this.myObj.format == 'Admit card'"
            >
              <b-form-group
                label="Exam Type"
                invalid-feedback="Exam type is required."
                ref="exType"
              >
                <v-select
                  v-model="eTypee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="examType"
                  :reduce="(opt) => opt.examType"
                  label="examType"
                  :clearable="false"
                  ref="exType"
                  placeholder="Select exam type"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="
                this.myObj.format == 'Admit card' ||
                this.myObj.format == 'ID card' ||
                (this.myObj.format == 'Attendance list' &&
                  $store.state.currentBranch.organizationType == 'coaching') ||
                (this.myObj.format == 'Class list' &&
                  $store.state.currentBranch.organizationType == 'coaching') ||
                (myObj.type.value == 'Students' &&
                  $store.state.currentBranch.organizationType == 'coaching')
              "
            >
              <b-form-group
                label="Session"
                invalid-feedback="Session is required."
                ref="session"
              >
                <v-select
                  v-model="myObj.sessionID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sessions"
                  :reduce="(opt) => opt.id"
                  label="session"
                  :clearable="false"
                  ref="session"
                  placeholder="Select session"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="this.myObj.format == 'Admit card'"
            >
              <b-form-group
                label="Select Date"
                invalid-feedback="Date is required."
              >
                <flat-pickr
                  :config="{
                    altFormat: 'j M, Y',
                    altInput: true,
                    dateFormat: 'd-F-Y',
                  }"
                  v-model="formattedDate"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>

            <b-col xl="2" lg="2" md="3" sm="12" cols="12" v-if="showAmount">
              <b-form-group
                label="Amount"
                invalid-feedback="amount is required."
                ref="amount"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="myObj.amounts"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="amountOptions"
                  :clearable="false"
                  ref="amount"
                  placeholder="Select amounts"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="this.myObj.format == 'Closing report'"
            >
              <b-form-group
                label="Select Date"
                invalid-feedback="Date is required."
                ref="closing_date"
              >
                <flat-pickr
                  ref="closing_date"
                  :config="singleDate"
                  v-model="closingDate"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col> -->

            <b-col xl="2" lg="2" md="3" sm="12" cols="12" v-if="showDate">
              <b-form-group
                label="Select Date"
                invalid-feedback="Date is required."
                ref="date"
              >
                <flat-pickr
                  :config="config"
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Select Date."
                  @on-change="setDate()"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="2"
              lg="2"
              md="3"
              sm="12"
              cols="12"
              v-if="
                reportDomain == 'myskoolstjacob' &&
                myObj.type.value == 'defaulters' &&
                (myObj.format == 'Class wise' || myObj.format == 'Family wise')
              "
            >
              <b-form-group
                invalid-feedback="Date is required."
                ref="jacobdate"
              >
                <div class="d-flex justify-content-between">
                  <label class="bv-no-focus-ring col-form-label pt-0"
                    >Last visit date
                    <!-- <feather-icon
                      class="cursor-pointer ml-50"
                      icon="InfoIcon"
                      size="16"
                      v-b-tooltip.hover.top
                      title="Select the last visit date to hide their data"
                    /> -->
                  </label>
                  <b-badge
                    variant="primary"
                    class="mb-50 cursor-pointer"
                    @click="jDate = null"
                  >
                    Clear
                  </b-badge>
                </div>
                <flat-pickr
                  ref="jacobdate"
                  :config="singleDate"
                  v-model="jDate"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              v-if="
                // (myObj.type.value == 'dcr' ||
                //   myObj.type.value == 'defaulters') &&
                myObj.type.value == 'dcr' &&
                !singleVisible.includes(myObj.format)
              "
            >
              <b-form-group
                label="Select Months"
                invalid-feedback="month is required."
                ref="months"
              >
                <div
                  style="
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <b-button
                    style="margin: 7px"
                    class="btn-icon"
                    v-for="mon in months"
                    :key="mon.id"
                    :variant="
                      variantMonth(mon.id) ? 'primary' : 'outline-primary'
                    "
                    @click="selectMonth(mon.id)"
                  >
                    {{ mon.name }}
                  </b-button>
                  <div
                    class="ml-50"
                    style="width: 120px"
                    v-if="myObj.type.value == 'defaulters'"
                  >
                    <b-form-group
                      label="Year"
                      invalid-feedback="year is required."
                      ref="yearD"
                    >
                      <!-- multiple
                    :closeOnSelect="false" -->
                      <v-select
                        multiple
                        v-model="myObj.year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="lastYears"
                        :reduce="(val) => val.id"
                        label="title"
                        :clearable="false"
                        ref="yearD"
                        placeholder="Select year"
                        @input="checkAccountYear()"
                      />
                    </b-form-group>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- month year range -->
            <b-col
              md="12"
              v-if="
                myObj.type.value == 'defaulters' &&
                !singleVisible.includes(myObj.format) &&
                myObj.format != 'Challan Statement'
              "
            >
              <b-form-group
                label="Select Month Range"
                invalid-feedback="month is required."
              >
                <div
                  style="
                    border: 1px solid lightgray;
                    padding: 8px;
                    border-radius: 5px;
                    /* display: flex;
                    flex-wrap: wrap;
                    align-items: center; */
                  "
                >
                  <b-row>
                    <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                      <b-form-group
                        label="Month from"
                        invalid-feedback="Month is required."
                        ref="fromMonth"
                      >
                        <v-select
                          v-model="monthFrom"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="monthsSOptions"
                          :clearable="false"
                          ref="fromMonth"
                          label="name"
                          :reduce="(val) => val.id"
                          placeholder="Select month"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                      <b-form-group
                        label="Year from"
                        invalid-feedback="year is required."
                        ref="fromYear"
                      >
                        <v-select
                          v-model="yearFrom"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="yearSOptions"
                          :clearable="false"
                          ref="fromYear"
                          label="title"
                          :reduce="(val) => val.id"
                          placeholder="Select year"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                      <b-form-group
                        label="Month till"
                        invalid-feedback="Month is required."
                        ref="tillMonth"
                      >
                        <v-select
                          v-model="monthTill"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="monthsSOptions"
                          :clearable="false"
                          ref="tillMonth"
                          label="name"
                          :reduce="(val) => val.id"
                          placeholder="Select month"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                      <b-form-group
                        label="Year till"
                        invalid-feedback="year is required."
                        ref="tillYear"
                      >
                        <v-select
                          v-model="yearTill"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="yearSOptions"
                          :clearable="false"
                          ref="tillYear"
                          label="title"
                          :reduce="(val) => val.id"
                          placeholder="Select year"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                      <b-button
                        class="mt-lg-2"
                        variant="outline-secondary"
                        @click="clearRange()"
                      >
                        <feather-icon icon="XIcon" />
                        Clear
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              v-if="
                myObj.type.value == 'dcr' ||
                (myObj.type.value == 'defaulters' &&
                  myObj.format != 'Challan Statement')
              "
            >
              <b-form-group
                label="Select Fee Type"
                invalid-feedback="Fee Type is required."
                ref="feetype"
              >
                <a class="my-buttons font-weight-bold">
                  <span v-if="!showAll" @click="showAll = true">Show More</span>
                  <span v-else @click="showAll = false">Show Less</span>
                </a>
                <!-- <feather-icon
                  v-if="!showAll"
                  v-b-tooltip.hover.left
                  title="Show"
                  class="my-buttons"
                  icon="ArrowDownIcon"
                  size="26"
                  @click="showAll = true"
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.left
                  title="Hide"
                  class="my-buttons"
                  icon="ArrowUpIcon"
                  size="26"
                  @click="showAll = false"
                /> -->
                <div
                  :class="{ 'my-collapsing': !showAll }"
                  style="
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <b-button
                    style="margin: 5px"
                    class="btn-icon"
                    v-for="fee in feeTypes"
                    :key="fee.id"
                    :variant="
                      variantFeetype(fee.id) ? 'primary' : 'outline-primary'
                    "
                    @click="selectFeetype(fee.id)"
                  >
                    {{ fee.feeType }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!dataLoading">
            <b-col
              xl="2"
              lg="2"
              md="4"
              sm="12"
              v-if="
                myObj.type.value != 'Accounts' &&
                myObj.format !== 'Monthly summary' &&
                myObj.type.value !== 'Advance Accounts' &&
                myObj.type.value != 'Staff'
              "
            >
              <b-form-group
                label="Select Department"
                invalid-feedback="Department is required."
                ref="depID"
              >
                <v-select
                  v-model="selectedDep"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    { id: 0, name: 'All Departments' },
                    ...departments,
                  ]"
                  :clearable="false"
                  ref="depID"
                  label="name"
                  :reduce="(val) => val.id"
                  placeholder="Select department"
                  @input="setClasses()"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="3"
              lg="3"
              md="4"
              sm="12"
              v-if="
                myObj.type.value != 'Accounts' &&
                myObj.format !== 'Monthly summary' &&
                myObj.type.value !== 'Advance Accounts' &&
                myObj.type.value != 'Staff'
              "
            >
              <b-form-group
                label="Select Class"
                invalid-feedback="class is required."
                ref="class"
              >
                <v-select
                  ref="class"
                  multiple
                  :closeOnSelect="false"
                  v-model="classID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  @input="FillSections()"
                  placeholder="Select class"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="3"
              lg="3"
              md="4"
              sm="12"
              v-if="
                myObj.type.value != 'Accounts' &&
                myObj.format !== 'Monthly summary' &&
                myObj.type.value !== 'Advance Accounts' &&
                myObj.type.value != 'Staff'
              "
            >
              <b-form-group
                label="Select Section"
                invalid-feedback="section is required."
                ref="section"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="secID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="admittedSections"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  @input="checkSections()"
                  ref="section"
                  label="section"
                  placeholder="Select section"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="myObj.type.value == 'Staff'">
              <b-form-group
                label="Select Department"
                invalid-feedback="Department is required."
                ref="staffDep"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  ref="staffDep"
                  v-model="staffDepID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    { name: 'All Departments', id: 0 },
                    ...staffDeparts,
                  ]"
                  label="name"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="Select department"
                  @input="setDeps()"
                />
              </b-form-group>
            </b-col>

            <b-col xl="2" lg="2" md="3" sm="12" cols="12">
              <b-button
                variant="primary"
                class="mt-2"
                @click="showReport()"
                block
                :disabled="stLoading"
              >
                <b-spinner
                  v-if="stLoading"
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Show</span>
              </b-button>
            </b-col>

            <b-col xl="2" lg="2" md="3" sm="12" cols="12">
              <b-button
                variant="primary"
                :disabled="request || stLoading"
                class="mt-2"
                @click="exportReport()"
                block
              >
                <div v-if="request">
                  <b-spinner
                    small
                    variant="light"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </div>
                <span v-else>Export</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <!-- v-if="myObj.type.value == 'defaulters'" -->
        <b-card v-if="false">
          <b-row>
            <b-col md="12">
              <h4>Send Message</h4>
              <hr />
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Select template"
                invalid-feedback="Template is required."
                ref="temp"
              >
                <v-select
                  v-model="msgObj.msgID"
                  :disabled="otherLoading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="msgTemplates"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  ref="temp"
                  label="title"
                  placeholder="Select template"
                  @input="LoadMedia()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Message"
                invalid-feedback="Message is required."
                ref="msg"
              >
                <b-form-textarea
                  placeholder="your message."
                  v-model="msgObj.message"
                  rows="5"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1" align-h="end">
            <b-col xl="2" lg="2" md="3" sm="12" cols="12">
              <b-button
                variant="success"
                block
                @click="sendMsg()"
                :disabled="sending"
              >
                <b-spinner
                  v-if="sending"
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Send</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
var axios = require("axios");
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";
import * as XLSX from "xlsx";

export default {
  components: {
    //Multiselect,
    flatPickr,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,
    BFormTextarea,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          reportTypes: ch.reportTypes,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // restrict for szablc
      if (this.reportDomain == "myskoolszablc") {
        this.typeOptions = this.typeOptions.filter((el) =>
          [
            "dcr",
            "defaulters",
            "Students",
            "Attendance",
            "Certificates",
          ].includes(el.value)
        );
      }

      if (this.rights.reportTypes && this.rights.reportTypes.length > 0) {
        this.typeOptions = this.typeOptions.filter((el) =>
          this.rights.reportTypes.includes(el.value)
        );
      }
      // restrict for szablc
      if (this.reportDomain == "myskoolszablc") {
        let ind = this.typeOptions.findIndex((el) => el.value == "Attendance");
        if (ind != -1) {
          let obj = this.typeOptions.find((el) => el.value == "Attendance");
          obj.format = ["Student"];
          this.typeOptions.splice(ind, 1, obj);
        }
      }
      // console.log(this.typeOptions, this.rights.reportTypes);

      this.myObj.type = this.typeOptions[0];
      if (this.reportDomain == "myskoolveritas") {
        // this.typeOptions[1].format.push("Challan Statement");
        let ind = this.typeOptions.findIndex((el) => el.value == "defaulters");
        if (ind != -1) {
          let obj = this.typeOptions.find((el) => el.value == "defaulters");
          obj.format.push("Challan Statement");
          this.typeOptions.splice(ind, 1, obj);
        }
      }
      this.setFormat();
      // console.log(this.rights);
      this.LoadSettings();
      this.LoadData();
      this.loadAccounts();
      // this.loadAmounts();
      this.loadSessions();
      this.loadExam();
      this.loadDeparts();
      this.LoadTemplate();
      this.loadConsent();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      eTypee: "",
      formattedDate: new Date().toLocaleDateString("en-UK", {
        year: "numeric",
        day: "numeric",
        month: "long",
      }),
      accList: [],
      request: false,
      stLoading: false,
      dataLoading: false,
      showDate: false,
      typeOptions: [
        {
          text: "Collection Report",
          value: "dcr",
          format: [
            "Slip wise",
            "Slip range",
            "Class wise",
            "Fee categories wise",
            "Department wise",
            "Family wise",
            "Month wise",
            "Class and month wise",
            "Summary",
            "Summary Details",
            "Exemption",
          ],
        },
        {
          text: "Outstanding Report",
          value: "defaulters",
          format: [
            "Class wise",
            "Family wise",
            "Family head wise",
            "Family summary",
            "Department wise",
            "Summary",
            "Outstanding summary",
            "Monthly summary",
            "Deleted receipts",
          ],
        },
        {
          text: "Students",
          value: "Students",
          format: [
            "Class list",
            "Fee status",
            "Fee projection",
            "Family wise fees status",
            "Free ship",
            "Discount Percentage",
            "GR Registered",
            "Strength report",
            "New admission",
            "Left students",
            "Attendance list",
            "Housewise List",
            "Family List",
            "Discount list",
            "Missing contacts",
            "Consents",
          ],
        },
        {
          text: "Staff",
          value: "Staff",
          format: ["Staff List", "Staff List Date wise", "Deposit List"],
        },
        {
          text: "Attendance",
          value: "Attendance",
          format: ["Student", "Staff"],
        },
        {
          text: "Accounts",
          value: "Accounts",
          format: [
            "Expense report",
            "Income report",
            "Closing report",
            "Cashbook",
            "Monthly Fee",
            "Estimation",
          ],
        },
        {
          text: "Advance Accounts",
          value: "Advance Accounts",
          format: [
            "General Journal",
            "Ledger",
            "Trial Balance",
            "Income Statement",
            "Cash Flow",
            "Cheque Summary",
          ],
        },
        {
          text: "Certificates",
          value: "Certificates",
          format: [
            "Admit card",
            "ID card",
            "Gate Pass",
            "Transfer",
            "Character",
            "Provisional",
            //"Leaving"
            "Pickup card (Parent)",
            "Pickup card (Driver)",
          ],
        },
      ],

      statusOptions: [
        { text: "Present", value: "present" },
        { text: "Registered", value: "registered" },
        { text: "Left", value: "left" },
      ],
      staffStOptions: [
        { text: "Working", value: "present" },
        { text: "Applicant", value: "applicant" },
        { text: "Left", value: "left" },
      ],
      classID: [0],
      secID: [0],
      feeTypes: [],
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],

      fields: [
        { label: "Name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      rangeDate: null,
      closingDate: null,
      myObj: {
        id: 0,
        type: "",
        dtfrom: "",
        dtto: "",
        classIDs: [],
        secIDs: [],
        db: this.$store.state.userData.db,
        campusID: this.$store.state.userData.cId,
        format: "",
        months: [],
        feesTypeIDs: [],
        status: "present",
        accIDs: [],
        year: 0,
        account: 0,
        sessionID: 0,
        depID: [],
        balance: 0,
        consent: "",
        staffStatus: "present",
      },
      disPerc: 0,
      staffDeparts: [],
      staffDepID: [0],
      formatOptions: [],
      slipFrom: 0,
      slipTo: 0,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      singleDate: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      jDate: null,
      accTypes: [],
      coaTypes: [],
      coa: 0,
      amountOptions: ["Rs.0", "Rs.2000", "Rs.4000", "Rs.6400"],
      dateVisible: [
        "New admissions report",
        "Left students report",
        "New inquiries report",
        "Last increment report",
        "Staff",
        "Student",
        "Discount report",
        "Cancelled slips",
        "Deleted fee",
        "New admission",
        "Left students",
        "Expense report",
        "Income report",
        "Closing report",
        "Cashbook",
        "General Journal",
        "Ledger",
        "Trial Balance",
        "Income Statement",
        "Cash Flow",
        "Outstanding summary",
        "Cheque Summary",
        "Staff List Date wise",
      ],
      statusVisible: ["Class list", "Housewise List", "Family List"],
      singleVisible: [
        "Outstanding summary",
        "Monthly summary",
        "Monthly Fee",
        "Estimation",
      ],
      examType: ["FIRST TERM", "MID TERM", "FINAL TERM"],
      sessions: [],
      departments: [],
      selectedDep: 0,
      showAmount: false,
      singleMonth: new Date().getMonth() + 1,
      monthsSOptions: [
        {
          id: 1,
          name: "January",
        },
        {
          id: 2,
          name: "February",
        },
        {
          id: 3,
          name: "March",
        },
        {
          id: 4,
          name: "April",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "June",
        },
        {
          id: 7,
          name: "July",
        },
        {
          id: 8,
          name: "August",
        },
        {
          id: 9,
          name: "September",
        },
        {
          id: 10,
          name: "October",
        },
        {
          id: 11,
          name: "November",
        },
        {
          id: 12,
          name: "December",
        },
      ],
      singleYear: new Date().getFullYear(),
      yearSOptions: [
        {
          id: 2020,
          title: "2020",
        },
        {
          id: 2021,
          title: "2021",
        },
        {
          id: 2022,
          title: "2022",
        },
        {
          id: 2023,
          title: "2023",
        },
        {
          id: 2024,
          title: "2024",
        },
        {
          id: 2025,
          title: "2025",
        },
        {
          id: 2026,
          title: "2026",
        },
        {
          id: 2027,
          title: "2027",
        },
        {
          id: 2028,
          title: "2028",
        },
        {
          id: 2029,
          title: "2029",
        },
        {
          id: 2030,
          title: "2030",
        },
      ],
      lastYears: [
        {
          id: 0,
          title: "All",
        },
        {
          id: 2020,
          title: "2020",
        },
        {
          id: 2021,
          title: "2021",
        },
        {
          id: 2022,
          title: "2022",
        },
        {
          id: 2023,
          title: "2023",
        },
        {
          id: 2024,
          title: "2024",
        },
        {
          id: 2025,
          title: "2025",
        },
        {
          id: 2026,
          title: "2026",
        },
        {
          id: 2027,
          title: "2027",
        },
        {
          id: 2028,
          title: "2028",
        },
        {
          id: 2029,
          title: "2029",
        },
        {
          id: 2030,
          title: "2030",
        },
      ],
      showAll: false,
      months: [
        {
          id: 1,
          name: "Jan",
        },
        {
          id: 2,
          name: "Feb",
        },
        {
          id: 3,
          name: "Mar",
        },
        {
          id: 4,
          name: "Apr",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "Jun",
        },
        {
          id: 7,
          name: "Jul",
        },
        {
          id: 8,
          name: "Aug",
        },
        {
          id: 9,
          name: "Sep",
        },
        {
          id: 10,
          name: "Oct",
        },
        {
          id: 11,
          name: "Nov",
        },
        {
          id: 12,
          name: "Dec",
        },
      ],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      msgTemplates: [],
      msgObj: {
        msgID: 0,
        message: "",
        media: "",
      },
      sending: false,
      monthFrom: 0,
      yearFrom: 0,
      monthTill: 0,
      yearTill: 0,
      dateRange: [],
      consentList: [],
      dueObj: null,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    generateDateRange() {
      // Parse the start and end dates
      const start = new Date(
        `${this.yearFrom}-${String(this.monthFrom).padStart(
          2,
          "0"
        )}-01T10:30:00`
      );
      // const start = new Date("2024-02-20T10:30:00");
      const end = new Date(
        `${this.yearTill}-${String(this.monthTill).padStart(
          2,
          "0"
        )}-01T10:30:00`
      );
      // console.log(start, end);

      // Initialize the result array
      this.dateRange = [];
      // Loop through each month and year within the range
      let currentDate = new Date(start);
      while (currentDate <= end) {
        // Format the current date as "Month-Year" with a hyphen
        const formattedDate = `${currentDate.toLocaleString("en-US", {
          month: "short",
        })}-${currentDate.getFullYear()}`;

        // Add the formatted date to the result array
        this.dateRange.push(formattedDate);

        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      // console.log(this.dateRange);
    },
    clearRange() {
      this.monthFrom = 0;
      this.yearFrom = 0;
      this.monthTill = 0;
      this.yearTill = 0;
      this.dateRange = [];
    },

    async LoadMedia() {
      let obj = this.msgTemplates.find((el) => el.id == this.msgObj.msgID);
      this.msgObj.message = obj.message;
      this.msgObj.media = obj.media;
      // console.log(this.msgObj);
    },
    checkMsg() {
      var elem = this.$refs["msg"];
      if (this.msgObj.message.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkMsgBalance() {
      var elem = this.$refs["balance"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.myObj.balance);
      if (!re.test(this.myObj.balance) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else return (elem.state = true);
    },
    async sendMsg() {
      this.generateDateRange();
      if (this.checkMsg() == true) {
        this.sending = true;
        let data = {
          month: this.dateRange,
          cls: this.classID[0] !== 0 ? this.classID : [],
          sec: this.secID[0] !== 0 ? this.secID : [],
          status:
            !this.singleVisible.includes(this.myObj.format) &&
            this.myObj.status != ""
              ? this.myObj.status
              : "present",
          feeType: this.myObj.feesTypeIDs,
          balance: parseInt(this.myObj.balance),
          message: this.msgObj.message,
          media: this.msgObj.media,
        };
        // console.log(data);
        var config = {
          url:
            this.$store.state.domain +
            "Chat/DefaulterMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&userID=" +
            this.$store.state.userData.userID,
          body: data,
          message: "Message send successfully!",
          context: this,
          token: this.$store.state.userData.token,
        };
        var status = await this.post(config);
        // console.log(status);
        this.sending = false;
      }
    },

    checkYear() {
      if (
        this.myObj.type.value == "defaulters" ||
        this.myObj.type.value == "dcr"
      ) {
        // var elem = this.$refs["yearD"];
        // if (this.myObj.year == "") {
        //   return (elem.state = false);
        // } else {
        //   return (elem.state = true);
        // }
      } else return true;
    },
    checkSingleMonth() {
      if (this.singleVisible.includes(this.myObj.format)) {
        var elem = this.$refs["sMonth"];
        if (this.singleMonth == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkSingleYear() {
      if (this.singleVisible.includes(this.myObj.format)) {
        var elem = this.$refs["year"];
        if (!this.singleYear) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckDepartment() {
      if (this.myObj.format == "Monthly summary") {
        var elem = this.$refs["departs"];
        if (this.myObj.depID.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckFee() {
      if (this.myObj.format == "Monthly summary") {
        var elem = this.$refs["feetype"];
        if (this.myObj.feesTypeIDs.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkBalance() {
      if (
        this.myObj.type.value == "defaulters" &&
        (this.myObj.format == "Class wise" ||
          this.myObj.format == "Family wise")
      ) {
        var elem = this.$refs["balance"];
        var re = /^[0-9]+$/;
        let x = parseInt(this.myObj.balance);
        if (!re.test(this.myObj.balance) || isNaN(x) || x < 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkPerc() {
      if (this.myObj.format == "Discount Percentage") {
        var elem = this.$refs["perc"];
        var re = /^[0-9]+$/;
        let x = parseInt(this.disPerc);
        if (!re.test(this.disPerc) || isNaN(x) || x < 0 || x > 100) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckType() {
      var elem = this.$refs["type"];
      if (this.myObj.type == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setFormat() {
      // console.log(this.myObj.type);
      if (this.myObj.type.format.length > 0) {
        this.formatOptions = this.myObj.type.format;
        this.myObj.format = this.formatOptions[0];
      } else {
        this.formatOptions = [];
        this.myObj.format = "";
      }
      this.showAmount = false;
      this.setDateOption();
      // this.myObj.format = "";
      // if (
      //   this.myObj.type.value == "dcr" ||
      //   // this.myObj.type.value == "Accounts" ||
      //   this.myObj.format == "New admission" ||
      //   this.myObj.format == "Left students"
      // ) {
      //   this.showDate = true;
      // } else {
      //   this.showDate = false;
      // }
    },
    setDateOption() {
      // console.log(this.myObj.format);
      if (
        (this.myObj.type.value == "dcr" ||
          this.dateVisible.includes(this.myObj.format)) &&
        !["Slip range"].includes(this.myObj.format)
      ) {
        this.showDate = true;
      } else {
        this.showDate = false;
      }
      if (this.myObj.format == "Fee status report") this.showAmount = true;
      else this.showAmount = false;

      if (
        (this.myObj.format == "Attendance list" &&
          this.$store.state.currentBranch.organizationType == "coaching") ||
        (this.myObj.format == "Class list" &&
          this.$store.state.currentBranch.organizationType == "coaching")
      ) {
        this.myObj.sessionID = this.sessions.find(
          (el) => el.isCurrentSession
        ).id;
      }

      if (this.myObj.format == "Monthly summary") {
        this.myObj.feesTypeIDs = [];
      }
    },
    checkExam() {
      if (this.myObj.format == "Admit card") {
        var elem = this.$refs["exType"];
        if (this.eTypee == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkSession() {
      if (
        this.myObj.format == "Admit card" ||
        this.myObj.format == "ID card" ||
        (this.myObj.format == "Attendance list" &&
          this.$store.state.currentBranch.organizationType == "coaching") ||
        (this.myObj.format == "Class list" &&
          this.$store.state.currentBranch.organizationType == "coaching")
      ) {
        var elem = this.$refs["session"];
        if (this.myObj.sessionID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckCons() {
      if (this.myObj.format == "Consents") {
        var elem = this.$refs["cons"];
        if (this.myObj.consent == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },

    CheckStatus() {
      if (
        this.statusVisible.includes(this.myObj.format) ||
        (this.myObj.type.value == "defaulters" &&
          !this.singleVisible.includes(this.myObj.format) &&
          this.myObj.format != "Summary")
      ) {
        var elem = this.$refs["status"];
        if (this.myObj.status == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckFormat() {
      // console.log(this.myObj.format);
      var elem = this.$refs["format"];
      if (this.myObj.format == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAccount() {
      if (
        this.myObj.type.value == "dcr" &&
        !["Exemption", "Summary Details"].includes(this.myObj.format)
      ) {
        // console.log(this.myObj.account);
        var elem = this.$refs["acc"];
        if (this.myObj.account == null) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkMultiAccounts() {
      if (this.myObj.type.value == "Accounts") {
        // console.log(this.myObj.account);
        var elem = this.$refs["accounts"];
        if (this.accList.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkAccountYear() {
      if (this.myObj.year[this.myObj.year.length - 1] == 0) {
        this.myObj.year = [0];
      } else {
        this.myObj.year = this.myObj.year.filter((el) => el != 0);
      }
    },
    CheckDate() {
      if (
        (this.myObj.type.value == "dcr" ||
          // this.myObj.type.value == "Accounts" ||
          this.dateVisible.includes(this.myObj.format)) &&
        !["Slip range"].includes(this.myObj.format)
      ) {
        var elem = this.$refs["date"];
        if (!this.myObj.dtfrom || !this.myObj.dtto) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
      // else if (this.myObj.format == "Closing report") {
      //   var elem = this.$refs["closing_date"];
      //   // console.log(this.closingDate);
      //   if (this.closingDate == null || this.closingDate == "") {
      //     return (elem.state = false);
      //   } else return (elem.state = true);
      // }
      else return true;
    },
    CheckClass() {
      if (
        this.myObj.type.value != "Accounts" &&
        this.myObj.format !== "Monthly summary" &&
        this.myObj.type.value !== "Advance Accounts" &&
        this.myObj.type.value != "Staff"
      ) {
        var elem = this.$refs["class"];
        if (this.classID.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSec() {
      if (
        this.myObj.type.value != "Accounts" &&
        this.myObj.format !== "Monthly summary" &&
        this.myObj.type.value !== "Advance Accounts" &&
        this.myObj.type.value != "Staff"
      ) {
        var elem = this.$refs["section"];
        if (this.secID.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },

    async LoadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["student_list_with_dues"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.dueObj = res.find((el) => el.key == "student_list_with_dues");
      // console.log(this.dueObj)
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.feeTypes = await this.get(obj);
      // console.log("fee", this.feeTypes);

      var obj2 = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj2);
      // console.log("class", this.classes);
      this.classes.forEach((el) => this.classesOptions.push(el.cls));
      this.dataLoading = false;
    },

    async loadAccounts() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "CollectionAccounts?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj2);
      // console.log("acc", result);
      if (result !== "NotFound") {
        this.accTypes = result;
        this.accTypes.unshift({ account: "All", id: 0 });
      } else this.accTypes = [];

      var objHeads = {
        url:
          this.$store.state.domain +
          "Accounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let resultHeads = await this.get(objHeads);
      // console.log("acc", result);
      if (resultHeads !== "NotFound") {
        this.coaTypes = resultHeads;
        this.coaTypes.unshift({ title: "All", id: 0 });
      } else this.coaTypes = [];

      // this.accountType = this.$store.state.accountType;
    },

    async loadAmounts() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "Students/FeeStatus?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj2);
      // console.log("am", result);
      if (result !== "NotFound") {
        this.amountOptions = result;
        this.amountOptions.unshift({ amount: "All", id: 0 });
      } else this.amountOptions = [];
      // this.accountType = this.$store.state.accountType;
    },

    async loadSessions() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj);

      // console.log("Session", this.sessions);
    },
    async loadExam() {
      var obj = {
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.examType = await this.get(obj);
    },
    async LoadTemplate() {
      var obj = {
        url:
          this.$store.state.domain +
          "MessageTemplate?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.msgTemplates = await this.get(obj);
    },
    async loadConsent() {
      var obj = {
        url:
          this.$store.state.domain +
          "Consents/LoadConsent?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.consentList = res.result;
    },
    async loadDeparts() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.departments = await this.get(obj);

      var obj2 = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.staffDeparts = await this.get(obj2);
    },

    setDate() {
      // console.log(this.rangeDate);
      let date = this.rangeDate.split(" to ");
      // console.log(date);
      if (date.length == 1) {
        this.myObj.dtfrom = date[0];
        this.myObj.dtto = date[0];
      } else {
        this.myObj.dtfrom = date[0];
        this.myObj.dtto = date[1];
      }
      this.CheckDate();
      // console.log(this.myObj.dtfrom, this.myObj.dtto);
    },
    setAccounts() {
      if (this.accList[this.accList.length - 1] == 0) {
        this.accList = [0];
      } else {
        this.accList = this.accList.filter((el) => el != 0);
      }
    },
    setAccountsCOA() {
      if (this.coa[this.coa.length - 1] == 0) {
        this.coa = [0];
      } else {
        this.coa = this.coa.filter((el) => el != 0);
      }
    },
    variantFeetype(id) {
      return this.myObj.feesTypeIDs.includes(id);
    },
    selectFeetype(id) {
      if (this.myObj.format == "Monthly summary") {
        this.myObj.feesTypeIDs = [id];
      } else {
        if (this.myObj.feesTypeIDs.length == 0) {
          this.myObj.feesTypeIDs.push(id);
        } else {
          let data = this.myObj.feesTypeIDs.filter((el) => {
            return el !== id;
          });
          // console.log("data", data);
          if (data.length == this.myObj.feesTypeIDs.length) {
            this.myObj.feesTypeIDs.push(id);
          } else {
            this.myObj.feesTypeIDs = data;
          }
        }
        // console.log(this.myObj.feesTypeIDs);
        // console.log(this.variant(id));
      }
    },
    variantMonth(name) {
      return this.myObj.months.includes(name);
    },
    selectMonth(name) {
      if (this.myObj.months.length == 0) {
        this.myObj.months.push(name);
      } else {
        let data = this.myObj.months.filter((el) => {
          return el !== name;
        });
        // console.log("data", data);
        if (data.length == this.myObj.months.length) {
          this.myObj.months.push(name);
        } else {
          this.myObj.months = data;
        }
      }
      // console.log(this.myObj.months);
    },
    setClasses() {
      if (this.selectedDep == 0) {
        this.classesOptions = this.classes.map((el) => el.cls);
        this.classesOptions.unshift({ name: "All Classes", id: 0 });
        this.classID = [0];
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      } else {
        this.classesOptions = this.classes.reduce((acc, el) => {
          if (el.cls.departmentID == this.selectedDep) {
            acc.push(el.cls);
          }
          return acc;
        }, []);
        this.classID = this.classesOptions.map((el) => el.id);
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
        if (this.classID.length == 1) {
          let secs = this.classes.find(
            (el) => el.cls.id == this.classID[0]
          ).sections;
          secs.forEach((el) => {
            this.admittedSections.push(el);
          });
        }
      }
    },
    FillSections() {
      // console.log(this.classID);
      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //     console.log(this.classID);
      //   }
      if (this.classID[0] == 0 && this.classID.length !== 1) {
        this.classID = this.classID.filter((e) => e !== 0);
        // console.log(this.classID);
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.classID[this.classID.length - 1] !== 0 &&
        this.classID.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.classID[this.classID.length - 1] == 0) {
        this.classID = [0];
        // console.log(this.classID);
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      } else {
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      }
      this.CheckClass();
    },
    checkSections() {
      // console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        // console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        // console.log(this.secID);
      }
      this.CheckSec();
    },
    setDeps() {
      if (this.staffDepID.at(-1) == 0) {
        this.staffDepID = [0];
      } else {
        this.staffDepID = this.staffDepID.filter((el) => el != 0);
      }
    },
    checkStaffDep() {
      if (this.myObj.type.value == "Staff") {
        var elem = this.$refs["staffDep"];
        if (this.staffDepID.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else true;
    },

    checkRangeMonth() {
      if (
        this.myObj.type.value == "defaulters" &&
        !this.singleVisible.includes(this.myObj.format)
      ) {
        this.generateDateRange();
        return true;
        // if (this.dateRange.length == 0) {
        //   this.$bvToast.toast("Please select the month range correctly!", {
        //     title: "Error!",
        //     variant: "danger",
        //     toaster: "b-toaster-top-center",
        //   });
        //   return false;
        // } else return true;
      }
      return true;
    },
    CheckSlipFrom() {
      if (this.myObj.format == "Slip range") {
        var elem = this.$refs["spFrom"];
        let x = parseInt(this.slipFrom);
        if (isNaN(x) || x <= 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else true;
    },
    CheckSlipTo() {
      if (this.myObj.format == "Slip range") {
        var elem = this.$refs["spTo"];
        let x = parseInt(this.slipTo);
        if (isNaN(x) || x <= 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else true;
    },
    checkSlipRange() {
      if (this.myObj.format == "Slip range") {
        if (this.CheckSlipFrom() == false || this.CheckSlipTo() == false)
          return false;
        else {
          return parseInt(this.slipFrom) <= parseInt(this.slipTo);
        }
      } else true;
    },
    async loadStatement() {
      this.CheckSec();
      this.CheckClass();

      if (this.CheckSec() == false || this.CheckClass() == false) {
        this.request = false;
        this.stLoading = false;
        return this.$bvToast.toast("Please provide the required details.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        let cls = [];
        if (this.classID[0] !== 0) {
          cls = this.classID;
        }
        let sec = [];
        if (this.secID[0] !== 0) {
          sec = this.secID;
        }

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/GetChallanStatement?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          context: this,
          body: {
            clsIDs: cls,
            secIDs: sec,
          },
          token: this.$store.state.userData.token,
          subjects: true,
        });

        // console.log(status);

        if (Array.isArray(status)) {
          const worksheet = XLSX.utils.json_to_sheet(status);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
          XLSX.writeFile(workbook, "Statement.xlsx");
        }
        this.request = false;
        this.stLoading = false;
      }
    },
    async LoadFeeSummary() {
      if (this.CheckDate() == true) {
        this.stLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "feesummarydetails/loaddata?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId +
            "&dtFrom=" +
            this.myObj.dtfrom +
            "&dtTo=" +
            this.myObj.dtto,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);
        let url =
          `https://${this.reportDomain}.myskool.app/Fee/Show?type=summaryDetails&dbb=` +
          this.myObj.db +
          "&cID=" +
          this.myObj.campusID;
        window.open(url, "_blank");
        this.stLoading = false;
      } else {
        this.$bvToast.toast("Please provide the required details.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async LoadDues(val) {
      this.CheckSec();
      this.CheckClass();
      this.CheckDate();
      if (
        this.CheckSec() == false ||
        this.CheckClass() == false ||
        this.CheckDate() == false
      ) {
        return this.$bvToast.toast("Please provide the required details.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (val == "export") this.request = true;
        else this.stLoading = true;

        let cls = [];
        if (this.classID[0] !== 0) {
          cls = this.classID;
        }
        let sec = [];
        if (this.secID[0] !== 0) {
          sec = this.secID;
        }

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Students/CalculateDues?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          context: this,
          body: {
            clsIDs: cls,
            secIDs: sec,
            status: "left",
          },
          token: this.$store.state.userData.token,
          subjects: true,
        });

        let contr = val == "export" ? "ShowExport" : "Show";
        let url =
          `https://${this.reportDomain}.myskool.app/List/${contr}?type=left&dbb=` +
          this.myObj.db +
          "&cID=" +
          this.myObj.campusID +
          "&cls=" +
          cls +
          "&sec=" +
          sec +
          "&status=&mob=false&dtFrom=" +
          this.myObj.dtfrom +
          "&dtTo=" +
          this.myObj.dtto;

        if (this.$store.state.currentBranch.organizationType == "coaching") {
          url = url + "&session=" + this.myObj.sessionID;
        }
        if (val == "export") {
          axios
            .get(url)
            .then((response) => {
              // console.log(response.data.data);
              window.open(response.data.data, "_blank");
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        } else {
          window.open(url, "_blank");
        }
        this.request = false;
        this.stLoading = false;
      }
    },
    async showReport() {
      if (this.myObj.format == "Challan Statement") {
        this.stLoading = true;
        this.loadStatement();
      } else if (this.myObj.format == "Summary Details") {
        this.LoadFeeSummary();
      } else if (
        this.myObj.format == "Left students" &&
        this.dueObj &&
        this.dueObj.valueBool
      ) {
        this.LoadDues();
      } else {
        //console.log("ClsID", this.classID);
        if (this.classID[0] !== 0) {
          this.myObj.classIDs = this.classID;
        } else {
          this.myObj.classIDs = "";
        }
        if (this.secID[0] !== 0) {
          //console.log(this.secID);
          this.myObj.secIDs = this.secID;
        } else {
          this.myObj.secIDs = "";
        }
        // console.log(this.myObj);
        this.CheckStatus();
        this.CheckFormat();
        this.CheckAccount();
        this.CheckDate();
        this.checkExam();
        this.checkSession();
        // this.checkMultiAccounts();
        this.CheckType();
        this.CheckSec();
        this.CheckClass();
        this.checkSingleMonth();
        this.checkSingleYear();
        this.CheckDepartment();
        this.checkBalance();
        this.checkPerc();
        this.checkStaffDep();
        this.CheckCons();
        this.CheckSlipFrom();
        this.CheckSlipTo();
        if (
          this.CheckType() == false ||
          this.CheckSec() == false ||
          this.CheckClass() == false ||
          this.CheckFormat() == false ||
          this.CheckAccount() == false ||
          this.CheckDate() == false ||
          this.checkExam() == false ||
          this.checkSession() == false ||
          this.CheckStatus() == false ||
          // this.checkMultiAccounts() == false ||
          this.checkYear() == false ||
          this.checkSingleMonth() == false ||
          this.checkSingleYear() == false ||
          this.CheckDepartment() == false ||
          this.CheckFee() == false ||
          this.checkBalance() == false ||
          this.checkPerc() == false ||
          this.checkStaffDep() == false ||
          this.checkRangeMonth() == false ||
          this.CheckCons() == false ||
          this.CheckSlipFrom() == false ||
          this.CheckSlipTo() == false ||
          this.checkSlipRange() == false
        ) {
          return this.$bvToast.toast("Please provide the required details.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          if (this.myObj.type.value == "dcr") {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/Show?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              this.myObj.months +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&accId=" +
              this.myObj.account +
              "&slipFrom=" +
              this.slipFrom +
              "&slipTo=" +
              this.slipTo;
            window.open(url, "_blank");
          } else if (this.myObj.type.value == "Students") {
            // /List/Show?type=list&dbb=xobsJs/qaQc=&cID=1&cls=&sec=&status={status}&mob=false
            let ty = "list";
            if (this.myObj.format == "Strength report") ty = "strength";
            else if (
              this.myObj.format == "Fee status" ||
              this.myObj.format == "Fee projection"
            )
              ty = "feeStatus";
            else if (this.myObj.format == "Free ship") ty = "freeship";
            else if (this.myObj.format == "Discount Percentage")
              ty = "discount-perc";
            else if (this.myObj.format == "GR Registered") ty = "gr";
            else if (this.myObj.format == "New admission") ty = "admission";
            else if (this.myObj.format == "Left students") ty = "left";
            else if (this.myObj.format == "Attendance list") ty = "attendance";
            else if (this.myObj.format == "Housewise List") ty = "house";
            else if (this.myObj.format == "Family List") ty = "family";
            else if (this.myObj.format == "Discount list") ty = "discount";
            else if (this.myObj.format == "Missing contacts")
              ty = "missingcontacts";
            else if (this.myObj.format == "Family wise fees status")
              ty = "familywisefee";
            else if (this.myObj.format == "Consents") ty = "consents";

            if (
              this.$store.state.currentBranch.organizationType == "coaching"
            ) {
              if (ty == "list") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=" +
                  this.myObj.status +
                  "&session=" +
                  this.myObj.sessionID +
                  "&mob=false&dtFrom=" +
                  this.myObj.dtfrom +
                  "&dtTo=" +
                  this.myObj.dtto;
                window.open(url, "_blank");
              } else if (ty == "attendance") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&session=" +
                  this.myObj.sessionID +
                  "&status=&mob=false";
                // console.log(url);
                window.open(url, "_blank");
              } else if (
                ty == "list" ||
                ty == "admission" ||
                ty == "left" ||
                ty == "house" ||
                ty == "family"
              ) {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=" +
                  this.myObj.status +
                  "&mob=false&dtFrom=" +
                  this.myObj.dtfrom +
                  "&dtTo=" +
                  this.myObj.dtto +
                  "&session=" +
                  this.myObj.sessionID;
                // console.log(url);
                window.open(url, "_blank");
              } else if (ty == "discount-perc") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=&mob=false&perc=" +
                  this.disPerc +
                  "&session=" +
                  this.myObj.sessionID;
                // console.log(url);
                window.open(url, "_blank");
              } else if (ty == "consents") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&consent=" +
                  this.myObj.consent +
                  "&status=&mob=false";
                // console.log(url);
                window.open(url, "_blank");
              } else {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=&mob=false" +
                  "&session=" +
                  this.myObj.sessionID;
                // console.log(url);
                window.open(url, "_blank");
              }
            } else if (
              ty == "list" ||
              ty == "admission" ||
              ty == "left" ||
              ty == "house" ||
              ty == "family"
            ) {
              var url =
                `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=" +
                this.myObj.status +
                "&mob=false&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto;
              // console.log(url);
              window.open(url, "_blank");
            } else if (ty == "discount-perc") {
              var url =
                `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=&mob=false&perc=" +
                this.disPerc;
              // console.log(url);
              window.open(url, "_blank");
            } else if (ty == "consents") {
              var url =
                `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&consent=" +
                this.myObj.consent +
                "&status=&mob=false";
              // console.log(url);
              window.open(url, "_blank");
            } else {
              var url =
                `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=&mob=false";
              // console.log(url);
              window.open(url, "_blank");
            }
          } else if (this.myObj.type.value == "Staff") {
            let ty = "staff";
            if (this.myObj.format == "Deposit List") ty = "deposit";
            if (this.myObj.format == "Staff List Date wise")
              ty = "staffdatewise";

            var url =
              `https://${this.reportDomain}.myskool.app/List/Show?type=` +
              ty +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&status=" +
              this.myObj.staffStatus +
              "&mob=false&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&depID=" +
              this.staffDepID;
            // console.log(url);
            window.open(url, "_blank");
          } else if (this.myObj.type.value == "Accounts") {
            if (this.accList[0] !== 0) {
              this.myObj.accIDs = this.accList;
            } else {
              this.myObj.accIDs = "";
            }

            let ty = "expense";
            if (this.myObj.format == "Income report") ty = "income";
            else if (this.myObj.format == "Closing report") ty = "closing";
            else if (this.myObj.format == "Cashbook") ty = "cashbookNew";
            else if (this.myObj.format == "Monthly Fee") ty = "Receivables";
            else if (this.myObj.format == "Estimation") ty = "estimation";

            if (
              this.myObj.format == "Closing report" ||
              this.myObj.format == "Cashbook"
            ) {
              var url =
                `https://${this.reportDomain}.myskool.app/Account/Show?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&accID=0";

              window.open(url, "_blank");
            } else if (
              this.myObj.format == "Monthly Fee" ||
              this.myObj.format == "Estimation"
            ) {
              let m = 0;
              if (this.singleMonth < 10) m = `0${this.singleMonth}`;
              else m = this.singleMonth;
              let dt = `${this.singleYear}-${m}-01`;

              var url =
                `https://${this.reportDomain}.myskool.app/Account/Show?type=` +
                ty +
                "&dtFrom=" +
                dt +
                "&dtTo=" +
                dt +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&accID=0";

              window.open(url, "_blank");
            } else {
              var url =
                `https://${this.reportDomain}.myskool.app/Account/Show?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&accID=" +
                this.coa;

              window.open(url, "_blank");
            }
          } else if (this.myObj.type.value == "Advance Accounts") {
            let ty = "journal";
            if (this.myObj.format == "Ledger") ty = "ledger";
            if (this.myObj.format == "Trial Balance") ty = "trialBalance";
            if (this.myObj.format == "Income Statement") ty = "incomeStatement";
            if (this.myObj.format == "Cash Flow") ty = "cashFlow";
            if (this.myObj.format == "Cheque Summary") ty = "chequeSummary";

            if (this.myObj.format == "Ledger") {
              this.stLoading = true;
              var status = await this.get({
                url:
                  this.$store.state.domain +
                  "JournalEntry/SyncLedger?db=" +
                  this.$store.state.userData.db +
                  "&cID=" +
                  this.$store.state.userData.cId +
                  "&dtFrom=" +
                  this.myObj.dtfrom +
                  "&dtTo=" +
                  this.myObj.dtto,
                context: this,
                token: this.$store.state.userData.token,
              });
              this.stLoading = false;

              var url =
                `https://${this.reportDomain}.myskool.app/Account/Show?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&dptID=1&accID=" +
                this.coa;

              window.open(url, "_blank");
            } else {
              var url =
                `https://${this.reportDomain}.myskool.app/Account/Show?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&dptID=1&accID=0";

              window.open(url, "_blank");
            }
          } else if (this.myObj.type.value == "Attendance") {
            if (this.myObj.format == "Student") {
              var url =
                `https://${this.reportDomain}.myskool.app/Attendance/Show?type=student&dtFrom=` +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=";

              window.open(url, "_blank");
            } else {
              // var status = await this.get({
              //   url:
              //     this.$store.state.domain +
              //     "StaffAttendance/UpdateLateComing?db=" +
              //     this.$store.state.userData.db +
              //     "&cID=" +
              //     this.$store.state.userData.cId +
              //     "&dtFrom=" +
              //     this.myObj.dtfrom +
              //     "&dtTo=" +
              //     this.myObj.dtto,
              //   context: this,
              //   token: this.$store.state.userData.token,
              // });
              var url =
                `https://${this.reportDomain}.myskool.app/Attendance/ShowStaff?dtFrom=` +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&depID=0";
              //  +
              // "&status=";

              window.open(url, "_blank");
            }
          } else if (this.myObj.type.value == "Certificates") {
            if (this.myObj.format == "Admit card") {
              //Admit card
              // console.log(this.eTypee);
              // const currentDate = new Date();
              // const day = ("0" + currentDate.getDate()).slice(-2);
              // const month = currentDate.toLocaleString("default", {
              //   month: "short",
              // });
              // const year = currentDate.getFullYear();
              // const formattedDate = `${day}-${month}-${year}`;

              var url =
                `https://${this.reportDomain}.myskool.app/Certificate/Show?type=` +
                "admit-card" +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&exam=" +
                this.eTypee +
                "&session=" +
                this.myObj.sessionID +
                "&date=" +
                this.formattedDate;
              // console.log(url);
              window.open(url, "_blank");
            } else if (this.myObj.format == "ID card") {
              let sess = this.sessions.find(
                (el) => el.id == this.myObj.sessionID
              ).session;
              var url =
                `https://${this.reportDomain}.myskool.app/Certificate/Show?type=id-card&dbb=` +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&session=" +
                sess;
              window.open(url, "_blank");
            } else {
              let ty = "transfer";
              if (this.myObj.format == "Character") ty = "character";
              else if (this.myObj.format == "Leaving") ty = "leaving";
              else if (this.myObj.format == "Provisional") ty = "provisional";
              else if (this.myObj.format == "Gate Pass") ty = "gatepass";
              else if (this.myObj.format == "Pickup card (Parent)")
                ty = "pcparent";
              else if (this.myObj.format == "Pickup card (Driver)")
                ty = "pcdriver";

              var url =
                `https://${this.reportDomain}.myskool.app/Certificate/Show?type=${ty}&dbb=` +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&sID=0&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs;
              window.open(url, "_blank");
            }
          } else if (this.myObj.format == "Outstanding summary") {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/Show?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              this.singleMonth +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.singleYear +
              "&depID=" +
              this.selectedDep;
            window.open(url, "_blank");
          } else if (this.myObj.format == "Monthly summary") {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/Show?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              this.singleMonth +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.singleYear +
              "&depID=" +
              this.myObj.depID;
            window.open(url, "_blank");
          } else if (
            this.reportDomain == "myskoolstjacob" &&
            this.myObj.type.value == "defaulters" &&
            (this.myObj.format == "Class wise" ||
              this.myObj.format == "Family wise")
          ) {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/Show?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.jDate +
              "&dtTo=" +
              this.jDate +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              // this.myObj.months +
              this.dateRange +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.myObj.year +
              "&status=" +
              this.myObj.status +
              "&balance=" +
              this.myObj.balance;
            window.open(url, "_blank");
          } else if (this.myObj.type.value == "defaulters") {
            // console.log(this.dateRange);
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/Show?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              "&dtTo=" +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              // this.myObj.months +
              this.dateRange +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.myObj.year +
              "&status=" +
              this.myObj.status +
              "&balance=" +
              this.myObj.balance;
            window.open(url, "_blank");
          } else {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/Show?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              // this.myObj.months +
              this.dateRange +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.myObj.year +
              "&status=" +
              this.myObj.status +
              "&balance=" +
              this.myObj.balance;
            window.open(url, "_blank");
          }
        }
      }
    },

    async exportReport() {
      if (this.myObj.format == "Challan Statement") {
        this.request = true;
        this.loadStatement();
      } else if (
        this.myObj.format == "Left students" &&
        this.dueObj &&
        this.dueObj.valueBool
      ) {
        this.LoadDues("export");
      } else {
        //console.log("ClsID", this.classID);
        if (this.classID[0] !== 0) {
          this.myObj.classIDs = this.classID;
        } else {
          this.myObj.classIDs = "";
        }
        if (this.secID[0] !== 0) {
          //console.log(this.secID);
          this.myObj.secIDs = this.secID;
        } else {
          this.myObj.secIDs = "";
        }
        // console.log(this.myObj);
        this.CheckStatus();
        this.CheckFormat();
        this.CheckAccount();
        this.CheckDate();
        this.checkExam();
        this.checkSession();
        // this.checkMultiAccounts();
        this.CheckType();
        this.CheckSec();
        this.CheckClass();
        this.checkSingleMonth();
        this.checkSingleYear();
        this.CheckDepartment();
        this.checkBalance();
        this.checkPerc();
        this.checkStaffDep();
        this.CheckSlipFrom();
        this.CheckSlipTo();
        if (
          this.CheckType() == false ||
          this.CheckSec() == false ||
          this.CheckClass() == false ||
          this.CheckFormat() == false ||
          this.CheckAccount() == false ||
          this.CheckDate() == false ||
          this.checkExam() == false ||
          this.checkSession() == false ||
          this.CheckStatus() == false ||
          // this.checkMultiAccounts() == false ||
          this.checkYear() == false ||
          this.checkSingleMonth() == false ||
          this.checkSingleYear() == false ||
          this.CheckDepartment() == false ||
          this.CheckFee() == false ||
          this.checkBalance() == false ||
          this.checkPerc() == false ||
          this.checkStaffDep() == false ||
          this.checkRangeMonth() == false ||
          this.CheckSlipFrom() == false ||
          this.CheckSlipTo() == false ||
          this.checkSlipRange() == false
        ) {
          return this.$bvToast.toast("Please provide the required details.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          this.request = true;
          if (this.myObj.type.value == "dcr") {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/ShowExport?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              this.myObj.months +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&accId=" +
              this.myObj.account +
              "&slipFrom=" +
              this.slipFrom +
              "&slipTo=" +
              this.slipTo;

            axios
              .get(url)
              .then((response) => {
                // console.log(response.data.data);
                window.open(response.data.data, "_blank");
              })
              .catch((error) => {
                // Handle errors here
                console.error("There was an error!", error);
              });
          } else if (this.myObj.type.value == "Students") {
            let ty = "list";
            if (this.myObj.format == "Strength report") ty = "strength";
            else if (
              this.myObj.format == "Fee status" ||
              this.myObj.format == "Fee projection"
            )
              ty = "feeStatus";
            else if (this.myObj.format == "Free ship") ty = "freeship";
            else if (this.myObj.format == "Discount Percentage")
              ty = "discount-perc";
            else if (this.myObj.format == "GR Registered") ty = "gr";
            else if (this.myObj.format == "New admission") ty = "admission";
            else if (this.myObj.format == "Left students") ty = "left";
            else if (this.myObj.format == "Attendance list") ty = "attendance";
            else if (this.myObj.format == "Housewise List") ty = "house";
            else if (this.myObj.format == "Family List") ty = "family";
            else if (this.myObj.format == "Discount list") ty = "discount";
            else if (this.myObj.format == "Missing contacts")
              ty = "missingcontacts";
            else if (this.myObj.format == "Family wise fees status")
              ty = "familywisefee";
            else if (this.myObj.format == "Consents") ty = "consents";

            if (
              this.$store.state.currentBranch.organizationType == "coaching"
            ) {
              if (ty == "list") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=" +
                  this.myObj.status +
                  "&session=" +
                  this.myObj.sessionID +
                  "&mob=false&dtFrom=" +
                  this.myObj.dtfrom +
                  "&dtTo=" +
                  this.myObj.dtto;

                axios
                  .get(url)
                  .then((response) => {
                    // console.log(response.data.data);
                    window.open(response.data.data, "_blank");
                  })
                  .catch((error) => {
                    // Handle errors here
                    console.error("There was an error!", error);
                  });
              } else if (ty == "attendance") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&session=" +
                  this.myObj.sessionID +
                  "&status=&mob=false";

                axios
                  .get(url)
                  .then((response) => {
                    // console.log(response.data.data);
                    window.open(response.data.data, "_blank");
                  })
                  .catch((error) => {
                    // Handle errors here
                    console.error("There was an error!", error);
                  });
              } else if (
                ty == "list" ||
                ty == "admission" ||
                ty == "left" ||
                ty == "house" ||
                ty == "family"
              ) {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=" +
                  this.myObj.status +
                  "&mob=false&dtFrom=" +
                  this.myObj.dtfrom +
                  "&dtTo=" +
                  this.myObj.dtto +
                  "&session=" +
                  this.myObj.sessionID;

                axios
                  .get(url)
                  .then((response) => {
                    // console.log(response.data.data);
                    window.open(response.data.data, "_blank");
                  })
                  .catch((error) => {
                    // Handle errors here
                    console.error("There was an error!", error);
                  });
              } else if (ty == "discount-perc") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=&mob=false&perc=" +
                  this.disPerc +
                  "&session=" +
                  this.myObj.sessionID;

                axios
                  .get(url)
                  .then((response) => {
                    // console.log(response.data.data);
                    window.open(response.data.data, "_blank");
                  })
                  .catch((error) => {
                    // Handle errors here
                    console.error("There was an error!", error);
                  });
              } else if (ty == "consents") {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&consent=" +
                  this.myObj.consent +
                  "&status=&mob=false";
                // console.log(url);
                axios
                  .get(url)
                  .then((response) => {
                    // console.log(response.data.data);
                    window.open(response.data.data, "_blank");
                  })
                  .catch((error) => {
                    // Handle errors here
                    console.error("There was an error!", error);
                  });
              } else {
                var url =
                  `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                  ty +
                  "&dbb=" +
                  this.myObj.db +
                  "&cID=" +
                  this.myObj.campusID +
                  "&cls=" +
                  this.myObj.classIDs +
                  "&sec=" +
                  this.myObj.secIDs +
                  "&status=&mob=false" +
                  "&session=" +
                  this.myObj.sessionID;

                axios
                  .get(url)
                  .then((response) => {
                    // console.log(response.data.data);
                    window.open(response.data.data, "_blank");
                  })
                  .catch((error) => {
                    // Handle errors here
                    console.error("There was an error!", error);
                  });
              }
            } else if (
              ty == "list" ||
              ty == "admission" ||
              ty == "left" ||
              ty == "house" ||
              ty == "family"
            ) {
              var url =
                `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=" +
                this.myObj.status +
                "&mob=false&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto;

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else if (ty == "discount-perc") {
              var url =
                `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=&mob=false&perc=" +
                this.disPerc;

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else if (ty == "consents") {
              var url =
                `https://${this.reportDomain}.myskool.app/List/Show?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&consent=" +
                this.myObj.consent +
                "&status=&mob=false";
              // console.log(url);
              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else {
              var url =
                `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
                ty +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=&mob=false";

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            }
          } else if (this.myObj.type.value == "Staff") {
            let ty = "staff";
            if (this.myObj.format == "Deposit List") ty = "deposit";
            if (this.myObj.format == "Staff List Date wise")
              ty = "staffdatewise";

            var url =
              `https://${this.reportDomain}.myskool.app/List/ShowExport?type=` +
              ty +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&status=" +
              this.myObj.staffStatus +
              "&mob=false&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&depID=" +
              this.staffDepID;

            axios
              .get(url)
              .then((response) => {
                // console.log(response.data.data);
                window.open(response.data.data, "_blank");
              })
              .catch((error) => {
                // Handle errors here
                console.error("There was an error!", error);
              });
          } else if (this.myObj.type.value == "Accounts") {
            if (this.accList[0] !== 0) {
              this.myObj.accIDs = this.accList;
            } else {
              this.myObj.accIDs = "";
            }

            let ty = "expense";
            if (this.myObj.format == "Income report") ty = "income";
            else if (this.myObj.format == "Closing report") ty = "closing";
            else if (this.myObj.format == "Cashbook") ty = "cashbookNew";
            else if (this.myObj.format == "Monthly Fee") ty = "Receivables";
            else if (this.myObj.format == "Estimation") ty = "estimation";

            if (
              this.myObj.format == "Closing report" ||
              this.myObj.format == "Cashbook"
            ) {
              var url =
                `https://${this.reportDomain}.myskool.app/Account/ShowExport?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&accID=0";

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else if (
              this.myObj.format == "Monthly Fee" ||
              this.myObj.format == "Estimation"
            ) {
              let m = 0;
              if (this.singleMonth < 10) m = `0${this.singleMonth}`;
              else m = this.singleMonth;
              let dt = `${this.singleYear}-${m}-01`;

              var url =
                `https://${this.reportDomain}.myskool.app/Account/ShowExport?type=` +
                ty +
                "&dtFrom=" +
                dt +
                "&dtTo=" +
                dt +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&accID=0";

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else {
              var url =
                `https://${this.reportDomain}.myskool.app/Account/ShowExport?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&accID=" +
                this.coa;

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            }
          } else if (this.myObj.type.value == "Advance Accounts") {
            let ty = "journal";
            if (this.myObj.format == "Ledger") ty = "ledger";
            if (this.myObj.format == "Trial Balance") ty = "trialBalance";
            if (this.myObj.format == "Income Statement") ty = "incomeStatement";
            if (this.myObj.format == "Cash Flow") ty = "cashFlow";
            if (this.myObj.format == "Cheque Summary") ty = "chequeSummary";

            if (this.myObj.format == "Ledger") {
              var status = await this.get({
                url:
                  this.$store.state.domain +
                  "JournalEntry/SyncLedger?db=" +
                  this.$store.state.userData.db +
                  "&cID=" +
                  this.$store.state.userData.cId +
                  "&dtFrom=" +
                  this.myObj.dtfrom +
                  "&dtTo=" +
                  this.myObj.dtto,
                context: this,
                token: this.$store.state.userData.token,
              });

              var url =
                `https://${this.reportDomain}.myskool.app/Account/ShowExport?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&dptID=1&accID=" +
                this.coa;

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else {
              var url =
                `https://${this.reportDomain}.myskool.app/Account/ShowExport?type=` +
                ty +
                "&dtFrom=" +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&dptID=1&accID=0";

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            }
          } else if (this.myObj.type.value == "Attendance") {
            if (this.myObj.format == "Student") {
              var url =
                `https://${this.reportDomain}.myskool.app/Attendance/ShowExport?type=student&dtFrom=` +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&status=";

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else {
              // var status = await this.get({
              //   url:
              //     this.$store.state.domain +
              //     "StaffAttendance/UpdateLateComing?db=" +
              //     this.$store.state.userData.db +
              //     "&cID=" +
              //     this.$store.state.userData.cId +
              //     "&dtFrom=" +
              //     this.myObj.dtfrom +
              //     "&dtTo=" +
              //     this.myObj.dtto,
              //   context: this,
              //   token: this.$store.state.userData.token,
              // });

              var url =
                `https://${this.reportDomain}.myskool.app/Attendance/ShowStaffExport?dtFrom=` +
                this.myObj.dtfrom +
                "&dtTo=" +
                this.myObj.dtto +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&depID=0";

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            }
          } else if (this.myObj.type.value == "Certificates") {
            if (this.myObj.format == "Admit card") {
              //Admit card
              // console.log(this.eTypee);
              // const currentDate = new Date();
              // const day = ("0" + currentDate.getDate()).slice(-2);
              // const month = currentDate.toLocaleString("default", {
              //   month: "short",
              // });
              // const year = currentDate.getFullYear();
              // const formattedDate = `${day}-${month}-${year}`;

              var url =
                `https://${this.reportDomain}.myskool.app/Certificate/ShowExport?type=` +
                "admit-card" +
                "&dbb=" +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&exam=" +
                this.eTypee +
                "&session=" +
                this.myObj.sessionID +
                "&date=" +
                this.formattedDate;

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else if (this.myObj.format == "ID card") {
              let sess = this.sessions.find(
                (el) => el.id == this.myObj.sessionID
              ).session;
              var url =
                `https://${this.reportDomain}.myskool.app/Certificate/ShowExport?type=id-card&dbb=` +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs +
                "&session=" +
                sess;

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            } else {
              let ty = "transfer";
              if (this.myObj.format == "Character") ty = "character";
              else if (this.myObj.format == "Leaving") ty = "leaving";
              else if (this.myObj.format == "Provisional") ty = "provisional";
              else if (this.myObj.format == "Gate Pass") ty = "gatepass";
              else if (this.myObj.format == "Pickup card (Parent)")
                ty = "pcparent";
              else if (this.myObj.format == "Pickup card (Driver)")
                ty = "pcdriver";

              var url =
                `https://${this.reportDomain}.myskool.app/Certificate/ShowExport?type=${ty}&dbb=` +
                this.myObj.db +
                "&cID=" +
                this.myObj.campusID +
                "&sID=0&cls=" +
                this.myObj.classIDs +
                "&sec=" +
                this.myObj.secIDs;

              axios
                .get(url)
                .then((response) => {
                  // console.log(response.data.data);
                  window.open(response.data.data, "_blank");
                })
                .catch((error) => {
                  // Handle errors here
                  console.error("There was an error!", error);
                });
            }
          } else if (this.myObj.format == "Outstanding summary") {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/ShowExport?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              this.singleMonth +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.singleYear +
              "&depID=" +
              this.selectedDep;

            axios
              .get(url)
              .then((response) => {
                // console.log(response.data.data);
                window.open(response.data.data, "_blank");
              })
              .catch((error) => {
                // Handle errors here
                console.error("There was an error!", error);
              });
          } else if (this.myObj.format == "Monthly summary") {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/ShowExport?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              this.singleMonth +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.singleYear +
              "&depID=" +
              this.myObj.depID;

            axios
              .get(url)
              .then((response) => {
                // console.log(response.data.data);
                window.open(response.data.data, "_blank");
              })
              .catch((error) => {
                // Handle errors here
                console.error("There was an error!", error);
              });
          } else if (
            this.reportDomain == "myskoolstjacob" &&
            this.myObj.type.value == "defaulters" &&
            (this.myObj.format == "Class wise" ||
              this.myObj.format == "Family wise")
          ) {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/ShowExport?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.jDate +
              "&dtTo=" +
              this.jDate +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              // this.myObj.months +
              this.dateRange +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.myObj.year +
              "&status=" +
              this.myObj.status +
              "&balance=" +
              this.myObj.balance;

            axios
              .get(url)
              .then((response) => {
                // console.log(response.data.data);
                window.open(response.data.data, "_blank");
              })
              .catch((error) => {
                // Handle errors here
                console.error("There was an error!", error);
              });
          } else {
            var url =
              `https://${this.reportDomain}.myskool.app/Fee/ShowExport?type=` +
              this.myObj.type.value +
              "&dtFrom=" +
              this.myObj.dtfrom +
              "&dtTo=" +
              this.myObj.dtto +
              "&dbb=" +
              this.myObj.db +
              "&cID=" +
              this.myObj.campusID +
              "&month=" +
              // this.myObj.months +
              this.dateRange +
              "&feeType=" +
              this.myObj.feesTypeIDs +
              "&format=" +
              this.myObj.format +
              "&cls=" +
              this.myObj.classIDs +
              "&sec=" +
              this.myObj.secIDs +
              "&year=" +
              this.myObj.year +
              "&status=" +
              this.myObj.status +
              "&balance=" +
              this.myObj.balance;

            axios
              .get(url)
              .then((response) => {
                // console.log(response.data.data);
                window.open(response.data.data, "_blank");
              })
              .catch((error) => {
                // Handle errors here
                console.error("There was an error!", error);
              });
          }
          this.request = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.my-collapsing {
  height: 55px;
  overflow: hidden;
}
.my-collapsing.adjust {
  height: 39px;
}
.my-buttons {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}
</style>
